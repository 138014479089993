export const statusMessage = {
  not_started: 'Belum dimulai',
  started: 'Sudah dimulai',
  finished: 'Selesai',
};

export const statusColor = {
  not_started: 'blue',
  started: 'green',
  finished: 'red',
};
