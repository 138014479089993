const Footer = (props) => {
  return (
    <footer className="footer footer-transparent d-print-none">
      <div className="container">
        <div className="row text-center align-items-center flex-row-reverse">
          <p>Copyright © 2021 Admisi UIN Sunan Kalijaga</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
