import { useState } from 'react';
import { useAuth } from '../../contexts/auth';
import logo from '../../assets/img/logo_uin.png';
import logoLabel from '../../assets/img/logo_uin_label.png';

const Login = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const { login, isLoading } = useAuth();

  const onSubmit = (e) => {
    e.preventDefault();

    login({ username, pin: password });
  };

  return (
    <div className="antialiased border-top-wide border-primary d-flex flex-column">
      <div className="page" style={{ justifyContent: 'center' }}>
        <div className="container-tight py-4">
          <div className="text-center mb-4">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img className="me-2" src={logo} height="42" alt="" />
              <img src={logoLabel} height="36" alt="" />
            </div>
          </div>
          <form className="card card-md" method="POST" onSubmit={onSubmit}>
            <div className="card-body">
              <h2 className="card-title text-center mb-4">Login</h2>
              <div className="mb-3">
                <label className="form-label" htmlFor="username">
                  Username
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Masukkan username"
                  name="username"
                  id="username"
                  onChange={(e) => setUsername(e.target.value)}
                  value={username}
                  required
                />
              </div>
              <div className="mb-3">
                <label className="form-label" htmlFor="password">
                  Password
                </label>
                <div className="input-group input-group-flat">
                  <input
                    className="form-control"
                    type="password"
                    placeholder="Masukkan password"
                    name="password"
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                    value={password}
                    required
                  />
                </div>
              </div>

              <div className="form-footer">
                <button type="submit" className="btn btn-primary w-100">
                  {isLoading && (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                    ></span>
                  )}
                  Masuk
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
