import { Toast } from 'react-bootstrap';

const Notification = ({ show, onClose, message }) => {
  return (
    <div className="position-fixed bottom-0 end-0 p-3" style={{ zIndex: 5 }}>
      <Toast onClose={onClose} show={show} delay={3000} autohide>
        <Toast.Header closeButton={false}>
          <span className="avatar avatar-xs me-2">N</span>
          <strong className="me-auto">Notifikasi</strong>
          <small>Beberapa saat lalu</small>
          <button
            type="button"
            className="ms-2 btn-close"
            aria-label="Close"
            onClick={onClose}
          ></button>
        </Toast.Header>
        <Toast.Body>{message}</Toast.Body>
      </Toast>
    </div>
  );
};

export default Notification;
