import { HashRouter as Router, Route, Switch } from 'react-router-dom';

import { useAuth } from './contexts/auth';
import PrivateRoute from './routes/Private';
import GuestRoute from './routes/Guest';

function App() {
  const { isAuthenticated } = useAuth();
  return (
    <Router>
      <Switch>
        {isAuthenticated ? (
          <Route component={PrivateRoute} />
        ) : (
          <Route component={GuestRoute} />
        )}
      </Switch>
    </Router>
  );
}

export default App;
