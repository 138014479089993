import { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { IconCircleCheck } from '@tabler/icons';

import { useAuth } from '../../contexts/auth';
import { getData } from '../../stores/proctor';
import {
  getConfigurations,
  setMonitoringToken,
  getMonitoringToken,
} from '../../stores/configuration';
import { createLogs, getLogs } from '../../stores/logs';
import { storeBeritaAcara } from '../../stores/beritaAcara';
import feathersClient from '../../utils/feathers';
import { moment } from '../../utils/moment';
import { statusMessage, statusColor } from '../../constants/examStatus';

import Header from '../component/Header';
import UserCard from '../component/UserCard';
import Footer from '../component/Footer';
import DetailModal from '../component/DetailModal';
import Notification from '../component/Notification';
import EmptyState from '../component/EmptyState';

const Dashboard = (props) => {
  const { logout } = useAuth();
  const [data, setData] = useState(null);
  const [incomingLog, setIncomingLog] = useState(null);
  const [currentTime, setCurrentTime] = useState(moment().format());
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalData, setModalData] = useState(null);
  const [isToastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [isLoaded, setIsLoaded] = useState('false');
  const [examStatus, setExamStatus] = useState('not_started');
  const [isFinishModalOpen, setFinishModalOpen] = useState(false);
  const [isBeritaAcaraOpen, setBeritaAcaraOpen] = useState(false);
  const [beritaAcara, setBeritaAcara] = useState({});
  const [isBeritaAcaraLoading, setBeritaAcaraLoading] = useState(false);

  const timeFormat = 'HH:mm:ss';

  const golonganPegawai = ['BLU / Kontrak', 'I', 'II', 'III', 'IV'];

  useEffect(() => {
    const setupSocket = (locationId) => {
      feathersClient
        .authenticate({
          strategy: 'jwt',
          accessToken: getMonitoringToken(),
          locationId: locationId,
        })
        .catch((error) => console.error('Socket auth error', error));

      feathersClient
        .service('logs')
        .on('created', (log) => {
          setIncomingLog(log)
        });
    };

    const bootstrapAsync = async () => {
      try {
        let configurations = await getConfigurations();
        configurations = configurations.data;
        setMonitoringToken(configurations.monitoring_access_token);

        let payload = await getData();
        payload = payload.data;

        let { participants, berita_acara, ...rest } = payload;

        let logs = await getParticipantLogs(participants.map((p) => p.id));
        logs = logs.map((log) => {
          return {
            total: log.total,
            current: log.data.length > 0 ? log.data[0] : null,
          };
        });

        participants = participants.map((p) => {
          p.log = logs.find(
            (log) => (log.current && log.current.participant_id) === `${p.id}`
          );
          return p;
        });

        setData({ ...rest, participants });

        if (berita_acara) {
          setBeritaAcara(berita_acara);
        }
        setIsLoaded(true);

        if (payload.location) {
          setupSocket(payload.location.id);
        }
      } catch (error) {
        console.error(error);
        alert('Gagal memuat data, mohon muat ulang halaman');
      }
    };

    const getParticipantLogs = (participantIds) => {
      return Promise.all(
        participantIds.map((id) => {
          return getLogs({
            participant_id: id,
            media_type: 'image',
            $limit: 1,
            '$sort[createdAt]': -1,
          });
        })
      );
    };

    if (!data) {
      bootstrapAsync();
    }

    if (incomingLog) {
      let participants = data.participants;
      participants = participants.map((participant, index) => {
        if (`${participant.id}` === incomingLog.participant_id) {
          participant.log
            ? (participant['log'] = {
                total: participant.log.total + 1,
                current: incomingLog,
              })
            : (participant['log'] = { total: 1, current: incomingLog });
        }
        return participant;
      });

      setData({ ...data, participants });
      setIncomingLog(null);
    }
  }, [data, incomingLog]);

  useEffect(() => {
    const timer = setInterval(() => {
      const currentTime = moment();
      setCurrentTime(currentTime.format());

      if (data && data.schedule && data.schedule.schedule_details.length > 0) {
        const scheduleDetails = data.schedule.schedule_details;
        const startTime = moment(scheduleDetails[0].start_time, timeFormat);
        const endTime = moment(
          scheduleDetails[scheduleDetails.length - 1].end_time,
          timeFormat
        );

        if (currentTime.isAfter(endTime)) {
          setExamStatus('finished');
          clearInterval(timer);
          setFinishModalOpen(true);
        } else if (currentTime.isBetween(startTime, endTime)) {
          setExamStatus('started');
        } else {
          setExamStatus('not_started');
        }
      }
    }, 1 * 3000);

    return () => clearInterval(timer);
  }, [data]);

  const sendMessage = async (messageData, callback) => {
    try {
      await createLogs({
        ...messageData,
        sender_id: data && data.proctor.id,
      });
      showNotif('Pesan terkirim');
    } catch (error) {
      console.error(error);
      showNotif('Pesan gagal terkirim');
    }

    callback();
  };

  const showModal = (data) => {
    setModalData(data);
    setModalOpen(true);
  };

  const showNotif = (message) => {
    setToastMessage(message);
    setToastOpen(true);
  };

  const reformatTime = (time) => {
    return moment(time, 'HH:mm:ss').format('HH:mm');
  };

  const getExamTime = (scheduleDetails) => {
    return `Jam ${reformatTime(scheduleDetails[0].start_time)} - ${reformatTime(
      scheduleDetails[scheduleDetails.length - 1].end_time
    )}`;
  };

  const onChangeBerita = (e) => {
    setBeritaAcara({ ...beritaAcara, [e.target.name]: e.target.value });
  };

  const submitBeritaAcara = async (e) => {
    e.preventDefault();
    setBeritaAcaraLoading(true);
    if (data && data.proctor) {
      try {
        const res = await storeBeritaAcara(data.proctor.id, beritaAcara);
        console.log(res);
        setBeritaAcaraLoading(false);
        logout();
      } catch (error) {
        setBeritaAcaraLoading(false);
        console.error(error);
        alert('Gagal menyimpan berita acara, silahkan coba lagi');
      }
    }
  };

  return (
    <div className="wrapper">
      <Header
        onLogout={() => {
          setBeritaAcaraOpen(true);
        }}
        user={data && data.proctor}
        location={data && data.location}
      />

      {isLoaded && data && !data.schedule ? (
        <EmptyState onLogout={logout} />
      ) : (
        <div>
          <div className="page-wrapper">
            <div className="container-xl pt-3">
              <div className="page-header d-print-none">
                <div className="row align-items-center">
                  {data ? (
                    <div className="col">
                      <h2 className="page-title">{data.schedule.name}</h2>
                      <h4 className="text-muted mb-1">
                        {getExamTime(data.schedule.schedule_details)}
                        <span
                          className={`badge bg-${statusColor[examStatus]} ms-1`}
                        >
                          {statusMessage[examStatus]}
                        </span>
                      </h4>
                      <div className="text-muted">{data.location.name}</div>
                      <div className="text-muted">
                        {data.participants ? data.participants.length : '0'}{' '}
                        Peserta
                      </div>
                    </div>
                  ) : (
                    <div className="col" style={{ maxWidth: '300px' }}>
                      <div className="skeleton-heading"></div>
                      <div className="skeleton-line"></div>
                      <div className="skeleton-line"></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="page-body">
            <div className="container-xl">
              <div className="row row-cards">
                {data && data.participants
                  ? data.participants.map((item, index) => {
                      return (
                        <UserCard
                          key={index}
                          participant={item}
                          sendMessage={sendMessage}
                          currentTime={currentTime}
                          handleClickPhoto={showModal}
                        />
                      );
                    })
                  : Array(8)
                      .fill()
                      .map((item, index) => {
                        return <UserCard key={index} isLoading={true} />;
                      })}
              </div>
            </div>

            <DetailModal
              show={isModalOpen}
              toggle={setModalOpen}
              data={modalData}
            />

            <Notification
              show={isToastOpen}
              onClose={() => setToastOpen(false)}
              message={toastMessage}
            />

            <Modal
              show={isFinishModalOpen}
              backdrop="static"
              keyboard={false}
              centered
            >
              <div className="modal-status bg-primary"></div>
              <Modal.Body className="text-center py-4">
                <IconCircleCheck className="mb-2 text-primary icon-lg" />
                <h3>Ujian selesai</h3>
                <div className="text-muted">
                  Terimakasih sudah mengawasi jalannya ujian CBT UIN Sunan
                  Kalijaga Yogyakarta.
                </div>
              </Modal.Body>
              <Modal.Footer>
                <button
                  className="btn btn-primary ms-auto me-auto"
                  onClick={() => {
                    setFinishModalOpen(false);
                    setBeritaAcaraOpen(true);
                  }}
                >
                  Isi Berita Acara
                </button>
              </Modal.Footer>
            </Modal>

            <Modal
              show={isBeritaAcaraOpen}
              backdrop="static"
              keyboard={false}
              centered
            >
              <Modal.Header>
                <Modal.Title>Berita Acara Ujian</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <form id="formSubmitBerita" onSubmit={submitBeritaAcara}>
                  <div className="mb-3">
                    <label className="form-label">NIP</label>
                    <input
                      className="form-control"
                      type="text"
                      name="nip"
                      placeholder="Masukan NIP pengawas"
                      required
                      onChange={onChangeBerita}
                      value={(beritaAcara && beritaAcara.nip) || ''}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Nama</label>
                    <input
                      className="form-control"
                      type="text"
                      name="name"
                      placeholder="Masukkan nama pengawas"
                      required
                      onChange={onChangeBerita}
                      value={(beritaAcara && beritaAcara.name) || ''}
                    />
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Golongan</label>
                    <select
                      className="form-select"
                      name="golongan"
                      onChange={onChangeBerita}
                      required
                      value={(beritaAcara && beritaAcara.golongan) || ''}
                    >
                      <option value="" hidden>
                        Pilih golongan
                      </option>
                      {golonganPegawai.map((item, index) => (
                        <option value={index} key={index}>
                          {item}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="mb-3">
                    <label className="form-label">Unit Kerja</label>
                    <input
                      className="form-control"
                      type="text"
                      name="unit_kerja"
                      placeholder="e.g. Fakultas Sains dan Teknologi"
                      required
                      onChange={onChangeBerita}
                      value={(beritaAcara && beritaAcara.unit_kerja) || ''}
                    />
                  </div>
                  <div>
                    <label className="form-label">Keterangan Tambahan</label>
                    <textarea
                      rows="3"
                      className="form-control"
                      placeholder="e.g. Ujian berjalan lancar"
                      name="keterangan"
                      onChange={onChangeBerita}
                      value={(beritaAcara && beritaAcara.keterangan) || ''}
                    ></textarea>
                  </div>
                </form>
              </Modal.Body>
              <Modal.Footer>
                <button
                  form="formSubmitBerita"
                  className="btn btn-primary"
                  type="submit"
                >
                  {isBeritaAcaraLoading && (
                    <span
                      className="spinner-border spinner-border-sm me-2"
                      role="status"
                    ></span>
                  )}
                  Simpan
                </button>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default Dashboard;
