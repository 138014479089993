import { Switch, Route, Redirect } from 'react-router-dom';
import Login from '../views/pages/Login';

function Guest() {
  return (
    <Switch>
      <Route path="/login" component={Login} />
      <Redirect from="/" to="/login" />
    </Switch>
  );
}

export default Guest;
