export const messageTemplates = [
  'Berkas, Kartu, dan Foto Peserta Tidak Sesuai',
  'Peserta ujian tidak identik dengan identitas peserta',
  'Peserta menerima bantuan dari orang lain',
  'Peserta membuka alat atau referensi lain yang dilarang',
  'Peserta merokok saat ujian',
  'Ada orang lain di sekitar peserta',
  'Peserta tidak terlihat pada hasil rekam',
  'Pakaian peserta tidak sesuai / tidak sopan',
  'Peserta tidak hadir',
  'Peserta pindah / mengakiri sesi',
];
