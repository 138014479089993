import { useState, useRef } from 'react';
import logo from '../../assets/img/logo_uin.png';
import logoLabel from '../../assets/img/logo_uin_label.png';
import { IconUser } from '@tabler/icons';
import { useOutsideClickUtil } from '../../utils/outsideClickUtil';

const Header = ({ onLogout, user }) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);
  useOutsideClickUtil(dropdownRef, () => setDropdownOpen(false));

  return (
    <header className="navbar navbar-expand-md navbar-light d-print-none">
      <div className="container-xl">
        <h1 className="navbar-brand navbar-brand-autodark d-none-navbar-horizontal pe-0 pe-md-3">
          <a
            href="."
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img className="me-2" src={logo} height="36" alt="" />
            <img src={logoLabel} height="32" alt="" />
          </a>
        </h1>

        <div className="navbar-nav flex-row order-md-last">
          <div className="nav-item dropdown" ref={dropdownRef}>
            {/* eslint-disable-next-line */}
            <a
              className={`nav-link d-flex lh-1 text-reset p-0 ${
                isDropdownOpen ? 'show' : ''
              }`}
              href="#"
              data-bs-toggle="dropdown"
              aria-label="Open user menu"
              aria-expanded={isDropdownOpen}
              onClick={(e) => {
                e.preventDefault();
                setDropdownOpen(!isDropdownOpen);
              }}
            >
              {user && user.photo_url ? (
                <span
                  className="avatar avatar-sm"
                  style={{
                    backgroundImage: `url(${user.photo_url})`,
                  }}
                ></span>
              ) : (
                <span className="avatar avatar-sm">
                  <IconUser className="avatar-icon" />
                </span>
              )}
            </a>
            <div
              className={`dropdown-menu dropdown-menu-end dropdown-menu-arrow ${
                isDropdownOpen ? 'show' : ''
              }`}
              data-bs-popper="none"
            >
              {/* eslint-disable-next-line */}
              <a
                href="#"
                className="dropdown-item"
                onClick={(e) => {
                  e.preventDefault();
                  onLogout();
                }}
              >
                Logout
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
