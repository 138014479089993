import { store } from '../services/cbt/beritaAcara';

const storeBeritaAcara = (idProctor, data) => {
  return new Promise((resolve, reject) => {
    store(idProctor, data)
      .then((res) => resolve(res.data))
      .catch((error) => reject(error));
  });
};

export { storeBeritaAcara };
