import jwt from 'jsonwebtoken';
import { getToken } from './auth';
import { getDetail } from '../services/cbt/proctor';

const getData = () => {
  return new Promise((resolve, reject) => {
    const token = getToken();
    const decoded = jwt.decode(token);

    if (decoded && decoded.id_proctor) {
      getDetail(decoded.id_proctor)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error));
    } else {
      reject(new Error('Token fail to decode'));
    }
  });
};

export { getData };
