import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Image, Alert } from 'react-bootstrap';

import { moment } from '../../utils/moment';
import participantImgPlaceholder from '../../assets/img/participant_image_placeholder.jpg';

import { messageTemplates } from '../../constants/messageTemplates';

const UserCard = ({
  isLoading = false,
  participant = null,
  sendMessage,
  currentTime,
  handleClickPhoto,
}) => {
  const [dropdownValue, setDropdownValue] = useState('');
  const [messageInputValue, setMessageInputValue] = useState('');
  const [isSendingMessage, setSendingMessage] = useState(false);

  const getTimeDiffStr = (now, to) => {
    return moment.duration(moment(to).diff(moment(now))).humanize(true);
  };

  const handleSendMessage = () => {
    const messageBody =
      messageInputValue !== '' ? messageInputValue : dropdownValue;
    if (messageBody === '') {
      alert('Silahkan pilih pesan atau masukkan pesan pada kolom input');
    } else {
      setSendingMessage(true);
      sendMessage(
        {
          participant_id: participant.id,
          schedule_id: participant.id_schedule,
          location_id: participant.id_location,
          type: 'warning',
          media_type: 'text',
          flag: true,
          body: messageBody,
        },
        () => setSendingMessage(false)
      );
    }
  };

  const handleDropdownChange = (e) => {
    setMessageInputValue('');
    setDropdownValue(e.target.value);
  };

  const handleMessageInputChange = (e) => {
    setDropdownValue('');
    setMessageInputValue(e.target.value);
  };

  const showAlert = (detectionResult) => {
    let message;
    if (detectionResult.is_face_detected) {
      if (detectionResult.num_of_faces === 1) {
        return null;
      } else {
        message = 'Terdeteksi ada orang lain';
      }
    } else {
      message = 'Peserta tidak terdeteksi pada gambar';
    }

    return (
      <Alert variant="warning" className="text-left m-2">
        <h4 className="alert-title">{message}</h4>
        <div className="text-muted">Tegur peserta atau abaikan pesan ini bila pemberitahuan ini salah.</div>
      </Alert>
    )
  }

  return (
    <div className="col-md-6 col-lg-3">
      <div className="card">
        {isLoading || participant === null ? (
          <div className="card-body p-4 text-center">
            <div className="ratio ratio-1x1 mb-3">
              <div className="skeleton-image"></div>
            </div>
            <div className="skeleton-heading m-0"></div>
            <div className="skeleton-line"></div>
          </div>
        ) : (
          <div className="card-body p-0">

            {participant.log && participant.log.current.detection_result && (
              showAlert(participant.log.current.detection_result)
            )}

            <div className="p-4 text-center">
              {participant.log && participant.log.current.body ? (
                <div className="image-outer-wrapper">
                  <div className="image-inner-wrapper">
                    <Image
                      onClick={() => handleClickPhoto(participant)}
                      src={participant.log && participant.log.current.body}
                      rounded
                      fluid
                      thumbnail
                      style={{
                        cursor: 'pointer',
                      }}
                      className="rotate rotate-270"
                    />
                  </div>
                </div>
              ) : (
                <Image
                  onClick={() => handleClickPhoto(participant)}
                  src={participant.photo || participantImgPlaceholder}
                  rounded
                  fluid
                  thumbnail
                  style={{
                    cursor: 'pointer',
                  }}
                />
              )}
              {participant.log && (
                <div className="mb-3">
                  <span className="badge bg-warning">
                    Foto ke-{participant.log.total} (
                    {getTimeDiffStr(
                      currentTime,
                      participant.log.current.createdAt
                    )}
                    )
                  </span>
                </div>
              )}
              <h3 className="m-0 mb-1">
                <Link to={`/peserta/${participant.id}`}>{participant.name}</Link>
              </h3>
              <div className="text-muted mb-2">
                {participant.participant_number}
              </div>
              <div className="mb-1">
                <select
                  className="form-select"
                  onChange={handleDropdownChange}
                  value={dropdownValue}
                >
                  <option value="">Pilih teguran</option>
                  {messageTemplates.map((item, index) => {
                    return (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    );
                  })}
                </select>
              </div>
              <span className="mb-1">atau</span>
              <input
                className="form-control mb-2"
                type="text"
                placeholder="Kirim pesan"
                value={messageInputValue}
                onChange={handleMessageInputChange}
              />

              <button
                className="btn btn-primary"
                style={{ width: '100%' }}
                onClick={handleSendMessage}
              >
                {isSendingMessage && (
                  <span
                    className="spinner-border spinner-border-sm me-2"
                    role="status"
                  ></span>
                )}
                Kirim
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserCard;
