import { Modal } from 'react-bootstrap';
import { Image } from 'react-bootstrap';

import participantImgPlaceholder from '../../assets/img/participant_image_placeholder.jpg';

const DetailModal = ({ show = false, data, toggle }) => {
  const handleClose = () => toggle(false);

  return data ? (
    <Modal
      show={show}
      onHide={handleClose}
      size="lg"
      dialogClassName="modal-90w"
    >
      <Modal.Header>
        <Modal.Title>{data.name}</Modal.Title>
        <button
          type="button"
          className="btn-close"
          aria-label="Close"
          onClick={() => toggle(false)}
        ></button>
      </Modal.Header>
      <Modal.Body>
        <div className="container-xl">
          <div className="row">
            <div className="col-md-6 text-center">
              <h3 className="m-0 mb-2">Foto peserta</h3>
              <Image
                src={data.photo || participantImgPlaceholder}
                thumbnail
                rounded
              />
            </div>
            <div className="col-md-6 text-center">
              <h3 className="m-0 mb-2">Foto terakhir</h3>
              {data.log && data.log.current.body ? (
                <div className="image-outer-wrapper">
                  <div className="image-inner-wrapper">
                    <Image
                      src={data.log && data.log.current.body}
                      thumbnail
                      rounded
                      className="rotate rotate-270"
                    />
                  </div>
                </div>
              ) : (
                <Image src={participantImgPlaceholder} thumbnail rounded />
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  ) : null;
};

export default DetailModal;
