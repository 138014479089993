import logs from '../services/monitor/routes/logs';

const createLogs = (data) => {
  return new Promise((resolve, reject) => {
    logs
      .store(data)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const getLogs = (query) => {
  return new Promise((resolve, reject) => {
    logs
      .get(query)
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

export { createLogs, getLogs };
