import io from 'socket.io-client';
import feathers from '@feathersjs/client';
import auth from '@feathersjs/authentication-client';

// import { MONITORING_TOKEN_KEY } from '../stores/configuration';

const socket = io(process.env.REACT_APP_MONITORING_SERVICE_BASE_URL);
const client = feathers();

client.configure(feathers.socketio(socket));
client.configure(auth());

export default client;
