import api from './api';

const setToken = (token) => {
  api.defaults.headers.common['X-Access-Token'] = token;
};

const removeToken = () => {
  api.defaults.headers.common['X-Access-Token'] = null;
};

const login = (data) => api.post('/api/v2/proctors/login', data);

const authService = { setToken, removeToken, login };

export default authService;
