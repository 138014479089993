import { get } from '../services/cbt/configuration';

const MONITORING_TOKEN_KEY = 'MONITORING_TOKEN';

const getConfigurations = () => {
  return new Promise((resolve, reject) => {
    get()
      .then((response) => resolve(response.data))
      .catch((error) => reject(error));
  });
};

const setMonitoringToken = (token) => {
  localStorage.setItem(MONITORING_TOKEN_KEY, token);
};

const getMonitoringToken = (token) => {
  return localStorage.getItem(MONITORING_TOKEN_KEY);
};

const removeMonitoringToken = () => {
  localStorage.removeItem(MONITORING_TOKEN_KEY);
};

export {
  MONITORING_TOKEN_KEY,
  getConfigurations,
  setMonitoringToken,
  getMonitoringToken,
  removeMonitoringToken,
};
