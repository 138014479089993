import emptyIllustration from '../../assets/img/undraw_add_notes.svg';

const EmptyState = ({ onLogout }) => {
  return (
    <div className="page-body">
      <div className="container-xl d-flex flex-column justify-content-center">
        <div className="empty">
          <div className="empty-image mb-1">
            <img
              src={emptyIllustration}
              alt="Empty illustration"
              height="128"
            />
          </div>
          <p className="empty-title">Data tidak ditemukan</p>
          <p className="empty-subtitle">
            Tidak ada jadwal ujian yang sedang berjalan
          </p>
          <div className="empty-action">
            <button className="btn btn-danger" onClick={onLogout}>
              Keluar
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmptyState;
