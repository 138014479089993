import { Switch, Route, Redirect } from 'react-router-dom';
import Dashboard from '../views/pages/Dashboard';

function Private() {
  return (
    <Switch>
      <Route path="/dashboard" component={Dashboard} />
      <Redirect from="*" to="/dashboard" />
    </Switch>
  );
}

export default Private;
