import axios from 'axios';
import { getMonitoringToken } from '../../stores/configuration';

const instance = axios.create({
  baseURL: process.env.REACT_APP_MONITORING_SERVICE_BASE_URL,
});

instance.interceptors.request.use((config) => {
  config.headers = {
    Authorization: `Bearer ${getMonitoringToken()}`,
  };
  return config;
});

export default instance;
